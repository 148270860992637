export const environment = {
    production: false,
      appName: 'Logame BI',
      home: '/painel',
      api: 'http://logamebi.com.br',
      url: '',
  
      passwordRules: {
          minlength: 8,
          minLowercaseCharacterRule: 1,
          minUppercaseCharacterRule: 1,
          minDigitCharacterRule: 1,
          minSpecialCharacterRule: 1
      },
  };
  