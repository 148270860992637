import { 
  CanActivateFn,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { inject } from '@angular/core';
import { LocalStorageService } from '@services/local-storage.service';

export const verifyTokenGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router: Router = inject(Router);
  const storage: LocalStorageService = inject(LocalStorageService);

  const token = storage.get('token');

  return !!token || router.navigate(['/']);
};